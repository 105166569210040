.daily-life-page-wrapper {
  min-height: 73vh;
  min-width: 50%;
  background-color: var(--white);
  margin: auto;
  margin-top: 2px;
}

.daily-life-page-wrapper h1 {
  margin-top: 51px;
}

.daily-life-page-wrapper .page-description-paragraph {
  font-size: 22px;
  letter-spacing: 0px;
  color: #707070;
  max-width: 469px;
  margin: auto;
  margin-top: 24px;
}

.daily-life-page-wrapper h2 {
  margin-top: 56px;
}

.daily-life-page-wrapper .yes-or-no-container {
  display: flex;
  justify-content: space-between;
  min-width: 280px;
  max-width: 15%;
  margin: auto;
  margin-top: 34px;
  text-align: center;
}

.daily-life-page-wrapper .yes-or-no {
  min-width: 112px;
  width: 112px;
  height: 60px;
  font-size: 26px;
  box-shadow: 0px 1px 2px #00000029;
  border: 1px solid var(--white);
  border-radius: 8px;
  align-self: center;
  line-height: 60px;
  font-weight: bold;
  color: var(--lightBlue);
  cursor: pointer;
}

.daily-life-page-wrapper .yes-or-no-container .active {
  background-color: #54b51d;
  box-shadow: 0px 2px 4px #00000029;
  color: var(--white);
}

.daily-life-page-wrapper .questions-h3 {
  letter-spacing: 0px;
  color: var(--lightBlue);
  margin-top: 67px;
}

.daily-life-page-wrapper .radio-buttons-container {
  min-width: 40%;
  margin-inline: auto;
  margin-top: 26px;
  padding-left: 30px;
}

.daily-life-page-wrapper .radio-button-container {
  width: 40%;
  margin: auto;
  text-align: left;
}

.css-vqmohf-MuiButtonBase-root-MuiRadio-root.Mui-checked {
  color: var(--lightBlue) !important;
}

.daily-life-page-wrapper .radio-text {
  text-align: center;
  color: #707070;
  /* margin-inline: auto; */
}

.daily-life-page-wrapper .radio-text .MuiFormControlLabel-label {
  margin: 0;
  min-width: 210px;
  text-align: left;
}

.daily-life-page-wrapper .slider-title {
  color: #2f2f2f;
  font-size: 24px;
  margin-top: 96px;
}

.daily-life-page-wrapper .slider-description-paragraph {
  font-size: 20px;
  color: #606060;
  margin-top: 18px;
}

.daily-life-page-wrapper .cooking-for-kids-message {
  color: #606060;
  margin-top: 14px;
  font-size: 13px;
  opacity: 0.7;
}

.daily-life-page-wrapper .slider-daily-life-page {
  margin-bottom: 150px;
}

@media only screen and (max-width: 899px) {
  .daily-life-page-wrapper .page-description-paragraph {
    font-size: 14px;
    width: 90%;
  }
  .daily-life-page-wrapper h2 {
    font-size: 18px;
    width: 80%;
    margin: auto;
    margin-top: 50px;
  }
  .daily-life-page-wrapper .yes-or-no-container {
    max-width: 12%;
    min-width: 240px;
    margin-top: 50px;
  }
  .daily-life-page-wrapper .questions-h3 {
    font-size: 16px;
    max-width: 90%;
    margin: auto;
    margin-top: 70px;
  }
  .daily-life-page-wrapper .slider-title {
    font-size: 18px;
    width: 80%;
    margin: auto;
    margin-top: 100px;
  }
  .daily-life-page-wrapper .slider-description-paragraph {
    font-size: 14px;
    width: 80%;
    margin: auto;
    margin-top: 20px;
  }
  .daily-life-page-wrapper .slider-daily-life-page {
    width: 350px;
    margin-bottom: 100px;
  }
}

@media only screen and (max-width: 600px) {
  .daily-life-page-wrapper .slider-daily-life-page {
    width: 80%;
  }
  .daily-life-page-wrapper .radio-text {
    color: #707070;
    min-width: fit-content;
    padding-left: 10%;
  }
}

@media only screen and (max-width: 400px) {
  .daily-life-page-wrapper .yes-or-no-container {
    margin-top: 20px;
  }

  .daily-life-page-wrapper .questions-h3 {
    margin-top: 50px;
  }

  .daily-life-page-wrapper .radio-buttons-container {
    margin-top: 20px;
  }

  .daily-life-page-wrapper .slider-title {
    margin-top: 50px;
  }

  .daily-life-page-wrapper .slider-daily-life-page {
    width: 80%;
    margin-block: 50px;
  }
}
