.offer-page-container {
  width: 100%;
  margin-bottom: 0;
  min-height: 91vh;
}

.offer-page-container h2 {
  margin-top: 40px;
}

.offer-page-container .offer-page-main-paragraph {
  letter-spacing: 0px;
  color: #707070;
  font-size: 26px;
  margin-top: 100px;
  font-weight: 600;
}

.offer-page-container .content-div {
  display: flex;
  width: 60%;
  margin: auto;
  justify-content: space-around;
}

.offer-page-container .left-div {
  width: 40%;
  margin-inline: auto;
}

.offer-page-container .left-div h5 {
  letter-spacing: 0px;
  color: var(--white);
  width: 331px;
  height: 49px;
  line-height: 49px;
  background: #12aec5 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 2px #00000029;
  border-radius: 25px;
  font-weight: 400;
  margin: auto;
  margin-top: 98px;
}

.circles-container {
  position: relative;
  width: 300px;
}

.circles-container .circles-img {
  width: 150px;
  margin-top: 150px;
}

.offer-page-container .right-div {
  width: 40%;
  margin-inline: auto;
}

.offer-page-container .right-div h5 {
  letter-spacing: 0px;
  color: var(--white);
  width: 331px;
  height: 49px;
  line-height: 49px;
  background: #e8493d 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 2px #00000029;
  border-radius: 25px;
  font-weight: 400;
  margin: auto;
  margin-top: 98px;
}

.offer-page-container .paragraph-texts {
  width: 100%;
  margin: auto;
  height: 35vh;
  margin-top: 77px;
  text-align: center;
}

.offer-page-container .paragraph-texts p {
  letter-spacing: 0px;
  color: #4a4747;
  text-align: left;
  min-height: 100px;
  line-height: 1;
}

.offer-page-container .coach-image-and-text-container {
  position: relative;
  display: flex;
  margin: auto;
  width: 60%;
  justify-content: space-around;
  height: 102px;
  max-width: 100vw;
  min-width: 1083px;
  background: #11adc5 0% 0% no-repeat padding-box;
  margin-top: 50px;
}

.offer-page-container .coach-image-and-text-container .image-container {
  position: relative;
  bottom: 139px;
  line-height: 102px;
}

.offer-page-container .coach-image-and-text-container .text-container {
  height: 102px;
  margin-top: 20px;
  width: 70%;
  text-align: left;
}

.offer-page-container .coach-image-and-text-container h4 {
  letter-spacing: 0px;
  color: var(--white);
  max-width: 100%;
}

.offer-page-container .coach-image-and-text-container p {
  letter-spacing: 0px;
  color: var(--white);
  font-size: 16px;
  text-align: left;
  margin-top: 12px;
}

@media only screen and (max-width: 1300px) and (min-width: 1199px) {
  .offer-page-container .left-div h5 {
    font-size: 20px;
  }
  .offer-page-container .right-div h5 {
    font-size: 20px;
  }
}

@media only screen and (max-width: 1100px) and (min-width: 900px) {
  .offer-page-container .left-div h5 {
    font-size: 14px;
  }
  .offer-page-container .right-div h5 {
    font-size: 14px;
  }
}

@media only screen and (max-width: 1250px) {
  .offer-page-container .coach-image-and-text-container {
    width: 100%;
    justify-content: space-around;
    height: 102px;
    min-width: 600px;
  }
}

@media only screen and (max-width: 899px) {
  .offer-page-container .content-div {
    flex-direction: column-reverse;
    width: 70%;
  }

  .circles-container {
    display: none;
  }

  .offer-page-container .left-div {
    max-height: 400px;
    margin-bottom: 100px;
    border: 1px solid #11aecd1d;
    border-radius: 8px;
    box-shadow: 0px 8px 10px -4px #137e96fe;
    width: 90%;
    background-color: var(--lightBlue);
  }

  .offer-page-container .left-div h5 {
    margin-top: 35px;
    color: var(--lightBlue);
    background-color: var(--white);
  }

  .offer-page-container .right-div {
    max-height: 400px;
    margin-top: 50px;
    margin-bottom: 50px;
    border-radius: 8px;
    border: 1px solid #e8483d15;
    box-shadow: 5px 5px 2px #e8483d15;
    box-shadow: 0px 8px 10px -4px #862018;
    width: 90%;
    background-color: #e8493d;
  }

  .offer-page-container .right-div h5 {
    margin-top: 35px;
    color: #e8493d;
    background-color: var(--white);
  }

  .offer-page-container .paragraph-texts {
    height: fit-content;
    margin-top: 40px;
    margin-bottom: 20px;
  }

  .offer-page-container .paragraph-texts p {
    text-align: left;
    max-width: 100%;
    padding-inline: 2em;
    padding-bottom: 1em;
    line-height: 1.2;
    min-height: 60px;
    color: var(--white);
    font-weight: 600;
  }
}

@media only screen and (max-width: 779px) {
  .offer-page-container .offer-page-main-paragraph {
    margin-top: 50px;
  }

  .offer-page-container .coach-image-and-text-container {
    min-width: 0;
    height: 130px;
  }

  .offer-page-container .coach-image-and-text-container .image-container {
    bottom: 111px;
  }

  .offer-page-container .coach-image-and-text-container h4 {
    font-size: 14px;
    max-width: 100%;
  }

  .offer-page-container .coach-image-and-text-container p {
    font-size: 12px;
    max-width: 80%;
  }
}

@media only screen and (max-width: 350px) {
  .offer-page-container h2 {
    margin-top: 10px;
  }

  .offer-page-container .offer-page-main-paragraph {
    margin-top: 50px;
    font-size: 16px;
  }
  .offer-page-container .left-div {
    margin-bottom: 50px;
    width: 100%;
  }

  .offer-page-container .right-div {
    margin-bottom: 50px;
    margin-top: 25px;
    width: 100%;
  }

  .offer-page-container .left-div h5,
  .offer-page-container .right-div h5 {
    margin-top: 25px;
  }

  .offer-page-container .paragraph-texts {
    height: fit-content;
    margin-top: 20px;
    margin-bottom: 10px;
  }

  .offer-page-container .paragraph-texts p {
    font-size: 12px;
  }

  .offer-page-container .coach-image-and-text-container {
    margin-top: 0;
  }

  .offer-page-container .coach-image-and-text-container .image-container {
    bottom: -30px;
  }
  .offer-page-container .coach-image-and-text-container .image-container img {
    max-height: 100px;
  }
}
