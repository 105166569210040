.home-container {
  display: flex;
  max-height: 100%;
  background-color: var(--white);
}

/*<===== COACH IMAGE, HOME PAGE =====>*/

.home-coach-image-wrapper {
  width: 30%;
  background-color: #10acc4cf;
  /* opacity: 0.82; */
  min-height: 100vh;
}

.home-coach-image-wrapper .home-coach-image-title {
  margin: auto;
  margin-top: 180px;
  width: 90%;
  margin-bottom: 50px;
  font-family: "Roboto";
  color: var(--white);
}

.home-page-image-text-container {
  position: relative;
  margin-top: 1%;
}

.home-page-image-text-container img {
  margin-top: -50px;
  max-width: 80%;
  margin-bottom: 2vh;
}

/*<===== SELECT PURPOSE, HOME PAGE =====>*/

.home-select-purpose-wrapper {
  width: 70%;
}

.oh-slim-logo-home {
  width: 337px;
  height: 106px;
  margin: auto;
  color: var(--lightBlue);
  margin-top: 39px;
}

.home-select-purpose-wrapper h1 {
  margin-top: 80px;
}

.home-select-purpose-wrapper .home-select-purpose-paragraph {
  margin-top: 30px;
}

.home-cards-container {
  display: flex;
  width: 36%;
  justify-content: space-around;
  margin: auto;
  margin-top: 74.5px;
}

.home-card {
  height: 208px;
  min-width: 272px;
  width: 272px;
  padding-top: 28px;
  box-shadow: 0px 2px 4px #00000029;
  border-radius: 8px;
  margin: 0 25px;
  cursor: pointer;
}

.active-card {
  background-color: var(--lightBlue);
}

.active-card .home-card-title {
  color: var(--white);
}

.home-card .home-card-title {
  margin-bottom: 20px;
}

.home-lose-weight-section .home-how-much-weight-to-lose {
  margin-top: 80px;
  color: #2f2f2f;
}

.home-lose-weight-section .home-select-purpose-secondary-paragraph {
  margin-top: 20px;
  font-size: 20px;
}

.home-select-purpose-wrapper Button {
  background-color: var(--lightGreen);
  color: #01140d;
  box-shadow: 0px 3px 6px #00000029;
  width: 319px;
  height: 63px;
  border: 1.5px solid white;
  border-radius: 50px;
  font-size: 22px;
  font-weight: bold;
  margin-top: 102px;
  margin-bottom: 69px;
  text-transform: none;
}

.home-select-purpose-wrapper Button:hover {
  background-color: var(--lightGreen);
}

.home-select-purpose-wrapper Button .double-arrow-font-size {
  font-size: 28px;
}

/* RESPONSIVENESS CSS */

@media only screen and (max-width: 1250px) {
  .home-container {
    display: flex;
    flex-direction: column-reverse;
  }

  .home-lose-weight-section .home-select-purpose-secondary-paragraph {
    font-size: 16px;
  }

  .home-select-purpose-wrapper {
    margin: auto;
    min-height: 70vh;
  }
  .home-select-purpose-wrapper h1 {
    margin-top: 50px;
  }
  .home-select-purpose-wrapper .home-select-purpose-paragraph {
    margin-top: 20px;
  }
  .home-card {
    height: 220px;
    min-width: 170px;
  }
  .home-cards-container svg {
    width: 80px;
    height: 80px;
    margin-bottom: 40px;
  }
  .home-coach-image-wrapper {
    width: 100%;
    min-height: 30vh;
    display: flex;
    flex-direction: row-reverse;
  }
  .home-coach-image-wrapper .home-coach-image-title {
    margin-top: 0;
    align-self: center;

    margin-right: 2vw;
  }

  .home-page-image-text-container img {
    margin-top: -10px;
  }
}

@media only screen and (max-width: 600px) {
  .oh-slim-logo-home {
    width: 200px;
    height: 60px;
  }

  .home-coach-image-wrapper .home-coach-image-title {
    width: 80%;
    margin-right: 20px;
    margin-top: 50px;
    font-size: 20px;
  }

  .home-page-image-text-container {
    margin-top: 10%;
    width: 100%;
  }

  .home-page-image-text-container img {
    margin-top: -20px;
  }

  .home-card {
    height: 150px;
    min-width: 120px;
    padding-top: 18px;
  }

  .home-cards-container svg {
    width: 70px;
    height: 70px;
    padding-bottom: 20px;
  }

  .home-select-purpose-wrapper Button {
    width: 250px;
    margin: 50px auto;
    border-radius: 30px;
  }

  .home-lose-weight-section .home-select-purpose-secondary-paragraph {
    font-size: 14px;
  }
}

@media only screen and (max-width: 400px) {
  .home-select-purpose-wrapper {
    width: 80%;
  }

  .home-cards-container {
    margin-top: 20px;
  }

  .home-card {
    margin: 0 10px;
  }

  .home-select-purpose-wrapper Button {
    font-size: 16px;
    width: 100%;
    margin-inline: auto;
  }

  .home-lose-weight-section .home-select-purpose-secondary-paragraph {
    font-size: 12px;
  }

  .home-coach-image-wrapper .home-coach-image-title {
    font-size: 18px;
  }

  .home-coach-image-wrapper {
    min-height: 100px;
  }

  .home-page-image-text-container img {
    max-width: 80%;
  }
}
