.order-book-page-wrapper {
  min-height: 100vh;
  margin-top: 2px;
  width: 100%;
  margin-inline: auto;
}

.order-book-page-wrapper .order-book-secondary-paragraph {
  margin-top: 50px;
}

.order-book-page-wrapper .order-book-main-title {
  margin-top: 50px;
}

.order-book-page-wrapper .order-book-main-paragraph {
  color: #707070;
  font-weight: 400;
  margin-top: 30px;
  max-width: 450px;
}

.main-plate-image-as-background-div {
  transform: rotate(-20deg);
  background-image: url("../../Assets/Images/Grafiken/ohslimteller.svg");
  background-repeat: no-repeat, repeat;
  height: 650px;
  width: 650px;
  opacity: 0.5;
  background-position: -200px;
  background-size: 600px;
  position: absolute;
  top: 250px;
  left: -150px;
  z-index: -10;
}

.main-book-image {
  max-width: 400px;
  max-height: 450px;
  margin-left: 10px;
  filter: drop-shadow(2px 10px 5px #00000029);
}

.personalise-book-container {
  display: flex;
  justify-content: space-around;
  margin-top: 120px;
  width: 60%;
  margin-inline: auto;
}

.personalise-book-container .personalize-book-texts-container {
  width: 450px;
  text-align: left;
  margin-right: 10px;
}

.personalize-book-texts-container .personalise-title {
  margin-inline: 0;
}

.personalize-book-texts-container .personalise-paragraph {
  margin-top: 20px;
  margin-inline: 0;
  max-width: 92%;
}

.order-book-page-inputs {
  display: flex;
  flex-direction: column;
  width: 275px;
  margin-top: 40px;
}

.order-book-page-inputs input {
  border: 1px solid var(--lightBlue);
  border-radius: 50px;
  height: 56px;
  margin-bottom: 24px;
  padding: 24px;
  font-size: 16px;
}

.order-now-section {
  margin-inline: auto;
  margin-top: 130px;
  position: relative;
}

.order-now-section h2 {
  font-size: 52px;
}

.order-now-section .order-now-paragraph {
  margin-top: 40px;
  margin-bottom: 20px;
  width: 600px;
}

.images-and-shape-container {
  margin-top: -750px;
}

.images-and-card-container {
  display: flex;
  width: 60%;
  margin-inline: auto;
  justify-content: space-around;
  position: relative;
  top: 25vh;
}

.plate-image-as-background-div {
  background-image: url("../../Assets/Images/Grafiken/ohslimteller.svg");
  background-repeat: no-repeat, repeat;
  height: 600px;
  opacity: 0.6;
  background-position: 85vw;
  background-size: 590px;
}

.images-and-card-container .order-now-section-coach-img {
  height: 330px;
  width: 280px;
  margin-top: auto;
}

.book-image-and-order-card {
  display: flex;
  max-width: 60%;
}

.images-and-card-container .order-now-section-book-img {
  height: 320px;
  width: 250px;
  margin-top: auto;
  z-index: 1;
  filter: drop-shadow(2px 10px 5px #00000029);
  transform: translate(80px, 30px);
}

.order-book-card-container {
  max-width: 290px;
  width: 290px;
  min-width: 275px;
  height: 525px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px;
  z-index: 2;
}
.order-book-card-container .order-card-price-normal {
  color: red;
  /*   text-decoration: line-through; */
  font-size: 30px;
}
.price-text-cents-normal {
  font-size: 18px;
}
.order-card-title-introductory {
  padding-top: 100px;
}
.red-line {
  border-bottom: 2px solid red;
  width: 80px;
  transform: translate(84px, 21px);
}

.order-book-card-container .price-text-cents {
  font-size: 30px;
}

.order-book-card-container .price-text-euro-sign {
  font-weight: 400;
}

.order-book-card-container .order-book-card-done-icon {
  font-size: 16px;
  padding-top: 4px;
  color: var(--lightBlue);
}

.order-book-card-container h4 {
  padding-top: 18px;
}

.order-book-card-container .order-book-advantages-list {
  text-align: left;
  width: 100%;
  margin-top: 22px;
  padding-left: 15%;
}

.order-book-card-container .order-book-advantages-list p {
  font-size: 14px;
  margin-bottom: 11px;
}

.order-book-card-container Button {
  margin-top: 20px;
  height: 35px;
  width: 168px;
  margin-inline: auto;
  background: #33be2d 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 4px #00000029;
  border: 1px solid #ffffff;
  border-radius: 10px;
  color: white;
  font-weight: 600;
  text-transform: none;
}

.order-book-card-container Button:hover {
  background-color: white;
  color: #33be2d;
  border: 1px solid #33be2d;
}

.triangle-shape {
  width: 60%;
  height: 0px;
  border-top: 30vh solid transparent;
  border-left: 70vw solid #40c7da;
  border-bottom: 0px solid transparent;
  margin: 0px auto 100px auto;
  z-index: 0;
}

.review-cards-and-titles-wrapper {
  padding-bottom: 200px;
}

.review-cards-and-titles-wrapper .review-cards-client-paragraph {
  margin-top: 110px;
}

.review-cards-and-titles-wrapper .review-cards-title {
  margin-top: 20px;
}

.review-cards-container {
  display: flex;
  display: table;
  border-spacing: 20px;
  justify-content: space-around;
  width: 70%;
  background-color: #40c7db;
  margin-inline: auto;
  margin-bottom: 240px;
  margin-top: 70px;
  padding: 1vh;
}

.review-card {
  display: table-cell;
  flex-direction: column;
  width: 333px;
  height: 254px;
  background-color: var(--white);
  box-shadow: 0px 2px 4px #00000029;
  border-radius: 8px;
  margin-block: auto;
  padding: 20px 30px;
  justify-content: space-between;
}

.review-card .stars-container {
  width: fit-content;
  justify-content: flex-start;
}

.star-color {
  color: #ebb911;
}

.review-card .user-review-comment {
  max-width: 100%;
  font-size: 14px;
  height: 150px;
  line-height: 1.1;
  color: #606060;
  text-align: left;
  max-height: 70%;
  margin-top: 5px;
}

.review-card-image-and-name-container {
  display: flex;
  justify-content: flex-start;
  margin-top: 30px;
}

.review-card-image-and-name-container .review-user-name-and-age {
  font-size: 11px;
  color: #606060;
  font-weight: 400;
  width: fit-content;
  max-width: 90%;
  margin-left: 10px;
}

.review-card-image-and-name-container .review-user-location {
  font-size: 9px;
  width: fit-content;
  max-width: 90%;
  margin-left: 10px;
}

.review-card-image-and-name-container img {
  height: 36px;
  width: 36px;
  border-radius: 50%;
  border: 1px solid #10adc4;
  box-shadow: 0px 3px 6px #00000029;
}

.review-card-image-and-name-container .user-name-and-location-container {
  display: flex;
  flex-direction: column;
  width: 200px;
}

@media only screen and (min-width: 1450px) {
  .red-line {
    transform: translate(90px, 22px); /* Adjusted for screens up to 1200px */
  }
}
@media only screen and (max-width: 1450px) {
  .main-plate-image-as-background-div {
    background-size: 500px;
    background-position: -150px;
    top: 280px;
  }

  .personalise-book-container {
    justify-content: space-between;
    width: 65%;
    padding-left: 5%;
  }

  .images-and-card-container .order-now-section-coach-img {
    height: 300px;
    width: 260px;
  }

  .plate-image-as-background-div {
    background-size: 500px;
  }

  .book-image-and-order-card {
    max-width: 70%;
  }

  .order-book-card-container {
    width: 280px;
  }

  .triangle-shape {
    width: 90%;
    border-left: 90vw solid #40c7da;
  }
}

@media only screen and (max-width: 1200px) {
  .main-plate-image-as-background-div {
    background-size: 400px;
    background-position: -80px;
    top: 300px;
  }
  .red-line {
    transform: translate(66px, 22px); /* Adjusted for screens up to 1200px */
  }

  .order-book-card-container {
    min-width: 230px;
    width: 250px;
    height: auto;
    padding-bottom: 20px;
  }

  .order-book-card-container .order-book-advantages-list {
    margin-top: 15px;
  }

  .order-book-card-container Button {
    margin-top: 10px;
  }

  .plate-image-as-background-div {
    background-size: 400px;
  }

  .main-book-image {
    max-width: 360px;
    max-height: 400px;
  }
}
@media only screen and (max-width: 1050px) {
  .personalise-book-container .personalize-book-texts-container {
    margin-top: 0;
  }
  .red-line {
    transform: translate(60px, 22px); /* Adjusted for screens up to 1050px */
  }

  .order-book-page-inputs {
    width: 250px;
  }

  .order-book-page-inputs input {
    height: 50px;
  }

  .main-book-image {
    max-width: 360px;
    max-height: 400px;
  }

  .images-and-card-container .order-now-section-coach-img {
    height: 240px;
    width: 200px;
  }

  .images-and-card-container .order-now-section-book-img {
    height: 260px;
    width: 200px;
  }

  .order-book-card-container {
    min-width: 200px;
    width: 220px;
    height: auto;
    padding-bottom: 20px;
  }

  .order-book-card-container h4 {
    padding-top: 15px;
    font-size: 16px;
  }

  .order-book-card-container .order-book-advantages-list {
    padding-left: 12%;
    margin-top: 10px;
  }

  .order-book-card-container Button {
    margin-top: 0px;
  }

  .order-book-card-container .order-book-advantages-list p {
    font-size: 12px;
  }

  .images-and-card-container {
    width: 70%;
    margin-left: 10%;
  }

  .review-cards-container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 90%;
    margin-bottom: 50px;
  }

  .review-card {
    width: 96%;
    margin-inline: auto;
    margin-block: 10px;
    height: 180px;
  }

  .review-card-image-and-name-container {
    margin: 0;
  }

  .review-card .user-review-comment {
    height: 80px;
  }
}

@media only screen and (max-width: 850px) {
  .main-plate-image-as-background-div {
    background-size: 300px;
    background-position: -50px;
    top: 250px;
  }
  .red-line {
    transform: translate(51px, 22px); /* Adjusted for screens up to 850px */
  }

  .plate-image-as-background-div {
    background-size: 300px;
  }

  .personalise-book-container {
    width: 60%;
  }

  .personalise-book-container .personalize-book-texts-container {
    margin-top: -50px;
  }

  .order-now-section h2 {
    font-size: 32px;
  }

  .order-book-card-container {
    height: auto;
    padding-bottom: 20px;
  }

  .order-book-card-container Button {
    margin-top: 20px;
  }

  .main-book-image {
    max-width: 240px;
    max-height: 300px;
  }

  .review-cards-and-titles-wrapper {
    padding-bottom: 50px;
  }
}

@media only screen and (max-width: 700px) {
  .main-plate-image-as-background-div {
    transform: none;
    width: 100%;
    background-size: 150px;
    background-position: 80px;
    top: 300px;
  }
  .red-line {
    transform: translate(60px, 22px); /* Adjusted for screens up to 700px */
  }

  .personalise-book-container {
    width: 60%;
    margin-inline: auto;
    flex-direction: column;
  }

  .personalise-book-container .personalize-book-texts-container {
    margin-top: 0px;
    text-align: center;
    width: 100%;
  }

  .order-book-page-inputs {
    width: 95%;
  }

  .main-book-image {
    display: none;
  }

  .order-now-section {
    margin-top: 50px;
  }

  .images-and-card-container {
    flex-direction: column-reverse;
    width: 90%;
  }

  .book-image-and-order-card {
    max-width: 98%;
    width: 98%;
    margin-inline: auto;
    margin-bottom: 30px;
  }

  .plate-image-as-background-div {
    transform: translateY(250px);
    background-size: 200px;
  }

  .images-and-card-container .order-now-section-coach-img {
    height: 240px;
    width: 200px;
    margin-bottom: -30px;
  }

  .images-and-card-container .order-now-section-book-img {
    height: 180px;
    width: 140px;
    margin-right: 15%;
  }

  .review-card {
    height: 250px;
  }

  .review-cards .review-cards-client-paragraph {
    margin-top: 50px;
  }

  .review-card .user-review-comment {
    font-size: 12px;
    height: 200px;
    line-height: 1.1;
  }

  .triangle-shape {
    margin-bottom: 20px;
  }

  .review-cards-container {
    margin-top: 30px;
  }
}

@media only screen and (max-width: 500px) {
  .images-and-card-container .order-now-section-book-img {
    transform: none;
    margin-right: -40px;
    width: 40%;
  }
  .red-line {
    transform: translate(60px, 20px); /* Adjusted for screens up to 500px */
  }
}

@media only screen and (max-width: 300px) {
  .order-book-card-container {
    min-width: 160px;
    width: 200px;
    height: 300px;
  }
  .red-line {
    transform: translate(50px, 20px); /* Adjusted for screens up to 500px */
  }

  .review-card .user-review-comment {
    font-size: 10px;
    height: 200px;
    line-height: 1.1;
  }
}
