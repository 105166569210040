.user-Information-page-wrapper {
  height: fit-content;
  min-width: 50%;
  background-color: var(--white);
  margin: auto;
  margin-top: 2px;
}

.user-Information-page-wrapper h1 {
  margin-top: 50px;
}

.user-Information-page-wrapper .first-paragraph {
  margin-top: 25px;
}

.user-Information-page-wrapper h3 {
  margin-top: 60px;
}

.user-Information-page-card-container {
  display: flex;
  margin: auto;
  width: 272px;
  justify-content: space-between;
}

.user-Information-page-card-container .card {
  margin-top: 38px;
  cursor: pointer;
}

.user-Information-page-card-container .card .card-image {
  margin-top: 28px;
  width: 98px;
  height: 53px;
  border-radius: 8px;
  box-shadow: 0px 1px 2px #00000029;
}

.user-Information-page-card-container .card .card-image .person-icon {
  margin: 15px auto;
}

.user-Information-page-card-container .card .card-image .person-icon-selected {
  margin: 15px auto;
  color: white;
}

.user-Information-page-card-container .card .card-image {
  margin: 15px auto;
}

.user-Information-page-card-container .card .active {
  background-color: #54b500;
  box-shadow: 0px 1px 2px #00000029;
  border: 1px solid var(--white);
  border-radius: 8px;
}

.user-Information-page-wrapper .h4-select {
  color: #606060;
}

.user-Information-page-wrapper .h4-select:first-child {
  margin-top: 60px;
  margin-bottom: 38px;
}

.user-Information-page-wrapper .dropdown-selector {
  width: 240px;
  height: 38px;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 8px;
  outline: 0px;
  border: 0px;
}

.user-Information-page-wrapper .dropdown-selector .dropdown-user-information {
  width: 240px;
  max-width: 240px;
  font-size: 20px;
  font-weight: 600;
  padding-left: 10px;
}

#dropdown-menu-item {
  font-size: 20px;
  font-weight: 600;
}

.height-weight-input {
  min-width: 240px;
  height: 38px;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 8px;
  outline: 0px;
  border: 0px;
  max-width: 240px;
  color: #606060;
  width: 240px;
  margin-inline: auto;
}

.height-weight-input .helper-text-styling {
  color: rgba(0, 0, 0, 0);
  margin-top: 5px;
  text-align: center;
}

.user-Information-page-wrapper .arbeit-sport-h2 {
  margin-top: 85px;
}

.user-Information-page-wrapper .question-about-day {
  margin-top: 34px;
}

.activity-level-selection-wrapper-flex,
.free-time-activity-wrapper-flex {
  min-width: 70%;
  max-width: 80%;
  margin: auto;
}

.activity-level-selection,
.free-time-activity {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  row-gap: 43px;
  column-gap: 12px;
  margin-top: 69px;
  align-items: center;
  justify-content: center;
}

.free-time-activity {
  margin-top: 47px;
}

.activity-level-selection button:last-child {
  grid-column-start: 4;
  grid-column-end: 10;
}

.activity-level-selection button,
.free-time-activity button {
  min-width: 325px;
  height: 46px;
  background: var(--white) 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 2px #00000029;
  border: 1px solid var(--lightBlue);
  border-radius: 8px;
  font: 18px;
  letter-spacing: 0px;
  color: var(--lightBlue);
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  grid-column: span 6;
}

.activity-level-selection button:hover,
.free-time-activity button:hover {
  border: 2px solid var(--lightBlue);
}

.activity-level-selection .clicked-activity-level-button,
.free-time-activity .clicked-activity-level-button {
  box-shadow: 0px 1px 2px #00000029;
  border: 1px solid var(--white);
  border-radius: 8px;
  background-color: var(--lightBlue);
  color: var(--white);
}

.user-Information-page-wrapper .question-sport,
.user-Information-page-wrapper .question-free-time {
  margin-top: 80px;
}

.BMI-calculator-wrapper {
  min-height: 54px;
  width: 246px;
  border: 1px solid var(--lightBlue);
  color: var(--lightBlue);
  margin: auto;
  margin-top: 50px;
}

.BMI-calculator-wrapper h4 {
  font-size: 18px;
  padding: 17px 19px;
}

@media only screen and (max-width: 899px) {
  .user-Information-page-wrapper {
    max-width: 100%;
  }

  .user-Information-page-wrapper .h4-select {
    font-size: 20px;
  }

  .user-Information-page-wrapper .h4-select:first-child {
    margin-top: 60px;
    margin-bottom: 30px;
  }

  .activity-level-selection,
  .free-time-activity {
    display: flex;
    flex-direction: column;
    row-gap: 0px;
  }

  .activity-level-selection button,
  .free-time-activity button {
    margin-bottom: 30px;
    font-size: 14px;
  }

  .activity-level-selection button:last-child {
    margin-left: 0;
  }

  .user-Information-page-wrapper .question-sport-h4 {
    font-size: 20px;
  }

  .user-Information-page-wrapper .user-Information-page-slider-container {
    width: 300px;
  }
}

@media only screen and (max-width: 499px) {
  .user-Information-page-wrapper h3 {
    margin-top: 50px;
  }

  .user-Information-page-card-container .card {
    margin-top: 20px;
  }

  .user-Information-page-card-container .card .card-image {
    margin: 20px auto 0;
  }

  .user-Information-page-card-container {
    width: 220px;
  }

  .user-Information-page-wrapper .h4-select {
    font-size: 16px;
  }

  .user-Information-page-wrapper .arbeit-sport-h2 {
    margin-top: 50px;
  }

  .user-Information-page-wrapper .question-about-day {
    font-size: 14px;
    margin-top: 20px;
  }

  .activity-level-selection {
    margin-top: 20px;
  }

  .activity-level-selection button,
  .free-time-activity button {
    margin-bottom: 20px;
    font-size: 14px;
    width: 240px;
    min-width: 240px;
  }

  .user-Information-page-wrapper .h4-select:first-child {
    margin-bottom: 20px;
  }

  .user-Information-page-wrapper .question-free-time {
    margin-top: 50px;
    font-size: 14px;
  }

  .free-time-activity {
    margin-top: 20px;
  }

  .user-Information-page-wrapper .question-sport,
  .user-Information-page-wrapper .question-free-time {
    margin-top: 50px;
    font-size: 14px;
  }

  .user-Information-page-wrapper .user-Information-page-slider-container {
    width: 250px;
    margin-top: 30px;
  }

  .user-Information-page-slider-container .MuiSlider-thumb {
    width: 70px;
  }

  .user-Information-page-slider-container .display-error-message .error-message-under-slider {
    margin-top: -40px;
  }
}
