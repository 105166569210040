.food-options-page-wrapper {
  width: 60%;
  min-height: 73vh;
  margin: auto;
  background-color: var(--white);
  margin-top: 2px;
}

.food-options-page-wrapper h1 {
  margin-top: 50px;
}

.food-options-page-wrapper .food-options-page-description-paragraph {
  margin-top: 25px;
  max-width: 460px;
}

.food-options-page-wrapper .food-options-page-food-toleration-paragraph {
  margin-top: 21px;
  width: 60%;
}

.food-options-page-wrapper .food-options-page-excluded-foods-paragraph {
  margin-top: 27px;
}

.food-options-page-wrapper .multiple-foods-selector {
  max-width: 90%;
  margin: auto;
}

.food-options-page-wrapper .multiple-foods-selector {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  margin-inline: auto;
}

.multiple-foods-selector button {
  height: 34px;
  width: 30%;
  box-shadow: 0px 1px 5px #00000029;
  border-radius: 10px;
  border: none;
  font-size: 13px;
  cursor: pointer;
}

.food-options-page-wrapper .select-all-foods-button {
  background-color: #54b51d;
  color: var(--white);
}

.food-options-page-wrapper .select-all-foods-button:hover {
  background-color: #0fcc05;
}

.food-options-page-wrapper .exclude-all-not-chosen-foods-button {
  background-color: #e68080;
  color: var(--white);
}

.food-options-page-wrapper .exclude-all-not-chosen-foods-button:hover {
  background-color: #e74343;
}

.food-options-page-wrapper .unselect-all-foods-button {
  color: var(--lightBlue);
  background-color: var(--white);
}

.food-options-page-wrapper .unselect-all-foods-button:hover {
  background-color: var(--lightBlue);
  font-weight: 600;
  color: var(--white);
}

.food-options-page-wrapper h2 {
  margin-top: 80px;
}

.food-options-page-wrapper .extra-excluded-foods-container h2 {
  margin-top: 105px;
}

.food-options-page-wrapper .food-toleration-container {
  margin-top: 45px;
}

.food-toleration-container .food-toleration-item {
  max-width: 394px;
  height: 46px;
  border: 1px solid var(--lightBlue);
  box-shadow: 0px 1px 2px #00000029;
  border-radius: 8px;
  margin: auto;
  margin-bottom: 18px;
  cursor: pointer;
}

.food-toleration-container .food-toleration-item:hover {
  border: 2px solid var(--lightBlue);
}

.food-toleration-container .food-toleration-item h5 {
  font-size: 18px;
  color: var(--lightBlue);
  text-align: left;
  padding-left: 20px;
  padding-top: 12px;
}

.food-toleration-container .clicked-food-toleration-item {
  background-color: var(--lightBlue);
  border: 1px solid var(--lightBlue);
  box-shadow: 0px 2px 4px #00000029;
}

.food-toleration-container .clicked-food-toleration-item h5 {
  color: var(--white);
}

/* FOOD SELECTION CARD STYLING */
.food-options-page-wrapper .foods-you-like-container {
  border-bottom: 1px solid #70707013;
  max-width: 80%;
  margin: auto;
  min-height: 104px;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  margin-top: 80px;
  row-gap: 40px;
  column-gap: 40px;
  align-items: center;
  justify-content: center;
  padding-bottom: 20px;
}

.foods-you-like-container .food-selection-card {
  height: 100px;
  width: 104px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  margin: auto;
  align-items: center;
  justify-content: center;
}

.foods-you-like-container .card-and-buttons-container:hover .food-selection-choices {
  opacity: 1;
}

.foods-you-like-container .food-selection-choices {
  display: flex;
  width: 78px;
  margin: auto;
  opacity: 0;
  box-shadow: 0px 0px 1px rgba(145, 145, 145, 0.572);
}

.foods-you-like-container > * {
  border-radius: 10px;
  height: 104px;
  padding: 5px;
}

.foods-you-like-container .food-selection-choices > * {
  height: 17px;
  width: 26px;
  color: var(--white);
  box-shadow: 0px 1px 2px #00000029;
  padding: 1px 2px;
  stroke: white;
  stroke-width: 2;
  border-radius: 0px 2px 2px 0px;
}

.foods-you-like-container .food-selection-choices > *:nth-child(1) {
  background-color: #54b51f;
}

.foods-you-like-container .food-selection-choices > *:nth-child(1):hover {
  background-color: #0fcc05;
}

.foods-you-like-container .food-selection-choices > *:nth-child(2) {
  background-color: #f7d02e;
}

.foods-you-like-container .food-selection-choices > *:nth-child(2):hover {
  background-color: #ffc400;
}

.foods-you-like-container .food-selection-choices > *:nth-child(3) {
  background-color: #e86a6a;
}

.foods-you-like-container .food-selection-choices > *:nth-child(3):hover {
  background-color: #e74343;
}

.food-selection-card img {
  max-width: 50px;
  max-height: 50px;
  padding-top: 15px;
}

.food-selection-card h6 {
  padding: 5px 0;
  font-size: 14px;
  color: var(--lightBlue);
  height: 30%;
  max-width: fit-content;
  margin-top: 5px;
  text-align: center;
}

.foods-you-like-container .liked-food {
  background-color: #54b51f;
}

.foods-you-like-container .liked-food h3 {
  content: "Mag ich";
}

.foods-you-like-container .neutral-food {
  background-color: #f7d024;
}

.foods-you-like-container .disliked-food {
  background-color: #e86a6a;
}

.card-and-buttons-container .food-selection-card .selected-food {
  color: var(--white) !important;
}

.search-food-to-exclude-container {
  display: flex;
  justify-content: center;
  margin-top: 12px;
  margin-bottom: 150px;
  max-height: 420px;
}

#ingredient-category-selection-listbox .food-options-exclude-list-item {
  width: fit-content;
  margin: 0;
  padding-top: 3px;
  color: rgba(54, 57, 62, 0.6);
  font-family: "Hind";
  font-weight: 600;
}

.search-food-to-exclude-container .x-icon-unselect-excluded-food {
  color: var(--white);
  font-size: 36px;
}

.search-food-to-exclude-container .search-icon-exclude-foods {
  color: var(--lightBlue);
  font-size: 40px;
  transform: scaleX(-1);
}

.search-food-to-exclude-container .exclude-food-search-input-text {
  font-size: 20px;
  color: #36393e;
  font-family: "Hind", sans-serif;
}

.search-food-to-exclude-container .exclude-foods-arrow-icon {
  color: var(--lightBlue);
  font-size: 40px;
}

.search-food-to-exclude-container .checked-checkbox-exclude-foods {
  color: var(--lightBlue);
}

/* <<< MODAL STYLING >>> */
.modal-wrapper {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
}

.modal-content {
  width: 460px;
  height: 460px;
  box-shadow: 0px 3px 6px #00000029;
  background-color: var(--white);
  border-radius: 10px;
}

.modal-content img {
  margin-top: 50px;
  height: 80px;
  width: 80px;
}

.modal-content h5 {
  margin-top: 70px;
}

.modal-content .modal-main-paragraph {
  margin-top: 18px;
  font-size: 18px;
}

.modal-content .modal-secondary-paragraph {
  margin-top: 48px;
  font-size: 12px;
}

.modal-content .modal-button {
  background-color: var(--lightGreen);
  color: #01140d;
  box-shadow: 0px 3px 6px #00000029;
  width: 247px;
  height: 45px;
  border: 1.5px solid white;
  border-radius: 50px;
  font-size: 22px;
  font-weight: bold;
  margin-top: 14px;
  text-transform: none;
  font-size: 14px;
}

.modal-content .modal-button:hover {
  background-color: var(--lightGreen);
}
/* <<< RESPONSIVENESS >>> */
@media only screen and (max-width: 900px) {
  .food-options-page-wrapper .foods-you-like-container {
    grid-template-columns: repeat(4, 1fr);
  }

  .food-options-page-wrapper .multiple-foods-selector {
    max-width: 100%;
  }

  .multiple-foods-selector button {
    width: 33%;
  }

  .search-food-to-exclude-container .exclude-food-search-input-text {
    font-size: 18px;
    color: #36393e;
    font-family: "Hind", sans-serif;
  }
}

@media only screen and (max-width: 755px) {
  .food-options-page-wrapper {
    width: 80%;
  }

  .food-options-page-wrapper .foods-you-like-container {
    grid-template-columns: repeat(2, 1fr);
  }

  .food-options-page-wrapper .multiple-foods-selector {
    flex-direction: column;
    width: 100%;
    margin: auto;
    align-items: center;
    margin-top: 20px;
  }

  .food-options-page-wrapper .multiple-foods-selector button {
    margin-top: 10px;
    height: 50px;
    font-size: 16px;
    font-weight: 600;
    width: 80%;
  }

  .foods-you-like-container > * {
    height: 111px;
    border-radius: 10px 10px 0 0;
    padding: 0;
  }

  .foods-you-like-container .food-selection-card {
    height: 110px;
  }

  .foods-you-like-container .food-selection-choices {
    width: 100%;
    opacity: 1;
    box-shadow: none;
  }

  .foods-you-like-container .food-selection-choices > * {
    height: 30px;
    padding: 2px;
    width: 33.33%;
  }

  .foods-you-like-container .food-selection-card {
    padding-block: 10px;
  }

  .css-6fen06 {
    width: 200px;
  }
}

@media only screen and (max-width: 480px) {
  .food-options-page-wrapper {
    width: 80%;
  }

  .food-options-page-wrapper .food-options-page-food-toleration-paragraph {
    margin-top: 21px;
    width: 100%;
  }

  .food-options-page-wrapper .foods-you-like-container {
    grid-template-columns: repeat(1, 1fr);
    row-gap: 100px;
  }

  .food-toleration-container .food-toleration-item {
    max-width: 240px;
  }

  .food-toleration-container .food-toleration-item h5 {
    font-size: 14px;
  }

  .search-food-to-exclude-container {
    margin-bottom: 170px;
    max-height: 320px;
  }

  .food-options-page-wrapper .extra-excluded-foods-container {
    margin-bottom: -60px;
  }

  .search-food-to-exclude-container .exclude-food-search-input-text {
    font-size: 14px;
    color: #36393e;
    font-family: "Hind", sans-serif;
  }
}

@media only screen and (max-width: 300px) {
  .food-options-page-wrapper {
    width: 100%;
  }
}

.ingredient-category-selection-root {
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
}

.ingredient-category-selection-focused {
  margin: 0 0 150px 0;
}

.ingredient-category-selection-input-wrapper {
  width: 400px;
  height: 45px;
  padding-top: 2px;
  box-shadow: 0px 1px 2px #00000029;
  background-color: var(--white);
  border-radius: 10px 10px 0px 0px;
  display: flex;
  flex-wrap: wrap;
}

.ingredient-category-selection-input-wrapper-open {
  border-radius: 10px 10px 0px 0px;
}

.ingredient-category-selection-input-wrapper-close {
  border-radius: 10px;
}

@media only screen and (max-width: 480px) {
  .ingredient-category-selection-input-wrapper {
    width: 260px;
  }
}

.ingredient-category-selection-input-wrapper > input {
  background-color: var(--white);
  color: rgba(0, 0, 0, 0.85);
  font-size: 24px;
  font-weight: bold;
  box-sizing: border-box;
  padding: 4px 6px;
  width: 0;
  min-width: 30px;
  flex-grow: 1;
  border: 0;
  margin: 0;
  outline: 0;
}

.ingredient-category-selection-listbox {
  width: 400px;
  margin: 0;
  padding: 0;
  position: absolute;
  list-style: none;
  background-color: var(--white);
  overflow: auto;
  max-height: 250px;
  border-radius: 0 0 10px 10px;
  box-shadow: 0px 2px 4px #00000029;
  margin-top: 2px;
  z-index: 1;
}

@media only screen and (max-width: 480px) {
  .ingredient-category-selection-input-wrapper {
    width: 260px;
  }
}

.ingredient-category-selection-listbox > li {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.ingredient-category-selection-listbox-li-focused {
  cursor: pointer;
}

.ingredient-category-selection-tag {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 400px;
  height: 46px;
  margin: 10px auto;
  line-height: 22px;
  background-color: rgba(204, 0, 0, 0.51);
  border-radius: 10px;
  box-sizing: content-box;
  outline: 0;
  overflow: hidden;
}

.ingredient-category-selection-tag > span {
  color: var(--white);
  padding: 0 0 0 10px;
  font-weight: bold;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.ingredient-category-selection-tag > svg {
  cursor: pointer;
  padding: 4px;
}

@media (max-width: 480px) {
  .ingredient-category-selection-tag {
    width: 260px;
    height: 35px;
    margin: 0px 0px 10px 0px;
  }
}
