/*<===== SLIDER OF THE HOME PAGE =====>*/

.main-slider-container {
  width: 20%;
  margin: auto;
  margin-top: 73px;
  width: 386px;
}

.main-slider-container .MuiSlider-root {
  width: 80%;
  height: 14px;
  cursor: pointer;
  transition: 0.2s;
  box-shadow: 0px 0px 0px #000000;
  border-radius: 50px;
  border: 0px solid #000000;
  background: #00e0ff;
  background: transparent linear-gradient(90deg, var(--lightBlue) 0%, #00e0ff 100%) 0% 0% no-repeat
    padding-box;
  padding: 0;
  color: transparent;
}

.main-slider-container .css-eg0mwd-MuiSlider-thumb:before {
  box-shadow: none;
}

.main-slider-container .MuiSlider-rail {
  background: #00e0ff;
  background: transparent linear-gradient(90deg, var(--lightBlue) 0%, #00e0ff 100%) 0% 0% no-repeat
    padding-box;
  width: 126%;
  right: -13%;
  opacity: 1;
}

.main-slider-container .MuiSlider-rail:focus {
  background: #00e0ff;
  background: transparent linear-gradient(90deg, var(--lightBlue) 0%, #00e0ff 100%) 0% 0% no-repeat
    padding-box;
}

.main-slider-container .MuiSlider-root.MuiSlider-dragging {
  color: transparent;
}

.main-slider-container .MuiSlider-thumb {
  box-shadow: 0px 0px 6px #00000029;
  border: 2px solid var(--white);
  height: 63px;
  width: 63px;
  border-radius: 50px;
  background: #00e09b;
  cursor: pointer;
  box-shadow: 0px 3px 6px #00000029;
}

.main-slider-container .MuiSlider-thumb:active,
.main-slider-container .MuiSlider-thumb:hover,
.main-slider-container .MuiSlider-thumb:focus {
  box-shadow: none;
  transition: none;
}

.thumb-text {
  background-color: transparent;
  margin-right: 1px;
  color: #000000;
  font-size: 16px;
  font-weight: bold;
}

.thumb-text-small {
  background-color: transparent;
  margin-left: 3px;
  color: #000000;
  font-size: 12px;
  font-weight: bold;
}

.thumb-text-small-user-Information-page {
  background-color: transparent;
  margin-left: 1px;
  color: #000000;
  font-size: 12px;
  font-weight: bold;
}

.slider-text {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 47px;
}

.slider-text p {
  color: #606060;
  opacity: 0.69;
  padding: 0 12px;
}

@media only screen and (max-width: 600px) {
  .main-slider-container {
    width: 95%;
  }
  .slider-text p {
    color: #606060;
    opacity: 0.69;
    padding: 0 0px;
  }
}
.main-slider-container .MuiSlider-thumb {
  height: 55px;
  width: 55px;
}

/*<===== SLIDER OF USERINFORMATION PAGE =====>*/

.user-Information-page-slider-container {
  margin-top: 58px;
  width: 501px;
}

.user-Information-page-slider-container .MuiSlider-thumb {
  box-shadow: 0px 0px 6px #00000029;
  border: 2px solid var(--white);
  width: 90px;
  height: 40px;
  border-radius: 50px;
  background: #00e09b;
  cursor: pointer;
  box-shadow: 0px 3px 6px #00000029;
}

.slider-text-user-Information-page {
  display: flex;
  width: 95%;
  justify-content: space-between;
  margin: auto;
  margin-top: 47px;
}

.slider-text-user-Information-page p {
  font-size: 12px;
  color: #606060;
  opacity: 0.69;
  padding: 0 12px;
  margin-bottom: 82px;
}

@media only screen and (max-width: 499px) {
  .user-Information-page-slider-container .MuiSlider-thumb {
    width: 70px;
  }

  .slider-text-user-Information-page {
    margin-top: 30px;
    width: 100%;
  }

  .slider-text-user-Information-page p {
    margin-bottom: 50px;
  }
}
